export const RoutePaths = Object.freeze({
  // Home
  HOMEPAGE: '/',

  // Admin
  // ADMIN_LOGIN: '/vendor/login',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_EMAIL_VERIFICATION: '/admin/otp-verify',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_SERVICES: '/admin/dashboard/services',
  ADMIN_CUSTOMER_SERVICE_DETAILS: '/admin/dashboard/services/:id',
  ADMIN_CUSTOMER_ACCEPTED_SERVICE_DETAILS:
    '/admin/dashboard/accepted-services/:id',
  ADMIN_CUSTOMERS: '/admin/dashboard/customers',
  ADMIN_PROVIDERS: '/admin/dashboard/providers',
  ADMIN_LOANS: '/admin/dashboard/loans',
  ADMIN_LOANS_REPAYMENT: '/admin/dashboard/loans-repayment',
  ADMIN_VIEW_LOANS_REPAYMENT: '/admin/dashboard/loans-repayment/:id',
  ADMIN_SETTINGS: '/admin/dashboard/settings',
  ADMIN_PROVIDER_DETAILS: '/admin/dashboard/providerDetails/:providerId',
  ADMIN_CUSTOMER_SERVICE_REQUESTS:
    '/admin/dashboard/ServiceRequestTable/:customerId',
  ADMIN_CUSTOMER_DETAILS: '/admin/dashboard/customerDetails/:customerId',
  ADMIN_ESSENTIALS_DETAILS: '/admin/dashboard/essentialsDetails/:id',
  ADMIN_LOAN_REPAYMENT_DETAILS: '/admin/dashboard/loan-repayment/:id',
  ADMIN_TRANSACTIONS: '/admin/dashboard/transactions/',
  ADMIN_INTEREST_RATES: '/admin/dashboard/interestRates', 
  ROLES_AND_PERMISSION: '/admin/dashboard/rolesAndPermissions', 
  INTERST_SETUP: '/admin/dashboard/interestSetup', 
  ADMIN_USERS: '/admin/dashboard/adminUsers', 
});
